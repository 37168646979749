<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient :client_id="client_id" @getClient="getClient"/>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
<!--/////////////// START FORM CONTENT ///////////////-->
                <div class="consent-form-content">
                    <hr>
                    <h1 style="text-align: center;">Policies and Consent for Treatment</h1>
                    <div class="avoid-break">
                        <h2>FINANCIAL POLICY</h2>
                        <p>I understand that payment is expected each time I receive services and at the time such services are rendered. Failure to remit payment can result in a refusal of services. Refusal of service will be considered an unexcused absence.</p>
                        <p>I understand that I am personally responsible for payment. Any balance not paid by the end of each session may be automatically charged to my designated card on file. I understand that my information will be saved to my file for future transactions on my account. I authorize Square One Clinics to charge my credit card on file for services rendered.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>INSURANCE BILLING</h2>
                        <p>Square One Clinics will bill insurance carriers on your behalf where applicable. This is a service we provide for our clients. Please note that you are still responsible for all charges incurred.</p>
                        <p>Should you lose insurance coverage at any point during treatment, you will be responsible for any treatment fees incurred. In such cases, our clients have the right to terminate treatment or seek treatment from another provider. If your insurance carrier fails to remit payment for services within ninety (90) days, you will be billed for the balance on the account.</p>
                        <p>All statements are due in full upon receipt. Square One Clinics does not provide refunds of any monies paid by or on the behalf of the Client if the Client leaves the facility against medical advice or for major rule violations.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>CANCELLATION POLICY</h2>
                        <p>Please help us to serve you and others better by keeping your scheduled appointments.If you need to cancel or reschedule, please give as much notice as possible so we can offer that time to someone else. Unless canceled at least 24 hours in advance, our policy is to charge for missed appointments at the rate of $25.00 per occurrence. We may require prepayment in order to schedule a subsequent appointment.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>ATTENDANCE POLICY</h2>
                        <p><b>IOP Level of Care - </b>Clients who have accrued more than 4 unexcused consecutive absences will be discharged from the program as non-compliant. Clients will be sent a letter of notice to this effect via email.</p>
                        <p><b>OP Level of Care - </b>Clients who have accrued 2 or more unexcused consecutive absences and/or have not been in contact with the facility for over 30 days will be inactivated. The client need only contact the facility and request to be reactivated in order to resume services.</p>
                        <p><b>SSC/DUI/Court-Ordered Clients - </b>Mandated clients are required to attend regularly and unexcused absences could be cause for discharge. Client absences will be reviewed and staffed with the Treatment Team weekly to determine appropriate course of action. If in jeopardy of being discharged for non-compliance, client will first be sent a warning letter via email. If subsequently discharged for non-compliance, client will be sent a letter of notice to this effect via email.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>CONFIDENTIALITY</h2>
                        <p>The confidentiality of client records maintained by this program is protected by Federal law and regulations. Generally, the program may not say to a person outside the program that a client attends the program, or disclose any information identifying a client unless:</p>
                        <ul>
                            <li>The client consents in writing;</li>
                            <li>The disclosure is allowed by court order;</li>
                            <li>The disclosure is made to medical personnel in a medical emergency or to qualified personnel for research audit or program evaluation;</li>
                            <li>It is suspected you are in danger of hurting yourself or others.</li>
                        </ul>
                        <p>Violation of Federal law and regulations by a program is a crime. Suspected violations may be reported to appropriate authorities in accordance with federal regulations.</p>
                        <p>Federal law and regulations do not protect any information about a crime committed by a client, either at the program or against any person who works for the program, or about any threat to commit such a crime.</p>
                        <p>Federal law and regulations do not protect any information about suspected child abuse or neglect from being reported under State law to appropriate State or local authorities. We are mandated to report any suspected child/elder/disabled abuse to the Abuse Hotline.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>PROGRAM INFORMATION &amp; GUIDELINES</h2>
                        <p>Office hours are as follows (Hours may change as needed) Monday 9:00 AM - 6:00 PM</p>
                        <p class="margin-0">Tuesday 9:00 AM - 6:00 PM</p>
                        <p class="margin-0">Wednesday 9:00 AM - 4:30 PM</p>
                        <p class="margin-0">Thursday 9:00 AM - 6:00 PM</p>
                        <p class="margin-0">Friday 10:00 AM - 1:00 PM</p>
                        <p>Office Telephone Number: (727) 295-1000</p>
                    </div>
                    <div class="avoid-break">
                        <h3>You may be terminated from the program for any of the following reasons:</h3>
                        <ul>
                            <li>Possession, use or sale of alcohol or drugs on premises</li>
                            <li>Overtly aggressive behavior towards anyone on premises</li>
                            <li>Sexual misconduct (verbal or behavioral) on premises</li>
                            <li>Repeated failure to keep appointments, attend group or failure to comply with your treatment plan</li>
                            <li>Absent from treatment for 30 consecutive days. It is at the discretion of your counselor if you would be required to start over if returning to treatment.</li>
                            <li>Repeated long periods of absences less than 30 days. It is at the discretion of your counselor if you would be required to start over if returning to treatment.</li>
                            <li>Refusal to submit to random drug/alcohol screening while in treatment.</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <p>The Following Items are identified and discussed in the Client Resources page of your Client Portal</p>
                        <ul>
                            <li>General Program Guidelines</li>
                            <li>Client Rights</li>
                            <li>Client Responsibilities</li>
                            <li>General Program Structure</li>
                            <li>Hours of Operation, Access Hours, &amp; Emergency Counseling Services</li>
                            <li>Confidentiality &amp; Limits of Confidentiality</li>
                            <li>Grievance Procedures</li>
                            <li>Infection Control Procedures</li>
                            <li>Educational Information Published by NIDA (National Institute on Drug Abuse): “Drug Abuse and the link to HIV/AIDS and Other Infectious Diseases”</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <h2>1. GENERAL PROGRAM GUIDELINES</h2>
                        <ul>
                            <li>I understand &amp; agree that while participating in any program at Square One Clinics, I may not consume or use any type of intoxicant unless prescribed for me by a physician due to a medical condition; Agree to advise Square One Clinics staff of any medications(s) prescribed to me prior to or following admission to services; Understand that I must submit to all random drug screens and/or breathalyzer testing requested by Square One Clinics staff; Understand that refusal to submit to testing or continued use of any substance, including alcohol, while receiving services at Square One Clinics could result in an extension, revision and/or termination of my original treatment plan or service contract.</li>
                            <li>I agree to conduct myself appropriately at all times understanding that I am in a setting where professional services are provided to persons with behavioral health problems. I will not use abusive or foul language and I will not make abusive or degrading statements to or about anyone.</li>
                            <li>I will respect the confidentiality of others and the treatment process. I understand no recording devices are allowed and regardless of the ability to record, cellular phones are not permitted in therapeutic sessions and must be powered off.</li>
                            <li>I will not behave in any way that would make anyone feel threatened.</li>
                            <li>I agree to comply with all federal and state laws while receiving services at Square One Clinics. Furthermore, if under any form of supervision by a court and/or probation I agree to comply with all orders of supervision.</li>
                            <li>I will attend sessions on time. Excessive tardiness can result in modification or extension of my treatment planI agree to follow the treatment plan/service contract as detailed above.</li>
                            <li>I understand that payment is expected each time I receive services at Square One Clinics and at the time such services are rendered. I understand per Square One Clinics policy, upon accumulated unexcused absence from services, I can be unsuccessfully discharged.</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <h2>2. CLIENT RIGHTS</h2>
                        <ul>
                            <li>Clients have the right to treatment without regard to; race, creed, sex, national origin or sexual preference.</li>
                            <li>Individuals will be treated with dignity and respect at all times.</li>
                            <li>Individuals have a right to confidential records within the law.</li>
                            <li>Clients have a right to access their personal records. Request for such records shall be in writing, and provided under the direction of the Clinical Director or COO.</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <h2>3. CLIENT RESPONSIBILITIES</h2>
                        <ul>
                            <li>Clients are responsible to provide complete, accurate and up-to-date information regarding past and present problems, both physical and emotional, that pertain to treatment.</li>
                            <li>The client is responsible for his/her own behavior and actions if treatment is refused or the treatment plan is not followed.</li>
                            <li>The client is responsible to respect the rights of others while on premises and during telehealth services.</li>
                            <li>If you feel that you are being abused or exploited, you may contact the Clinical Director/COO of Square One Clinics at (727) 295-1000.</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <h2>4. GRIEVANCE PROCEDURE</h2>
                        <p>Should you, at any time while receiving services from Square One Clinics, believe you are being treated in a manner or fashion which you consider to be unprofessional and/or not in keeping with your best interest as a client, you have the right to file a grievance and have that grievance reviewed and addressed by the Chief Administrator. A grievance form can be requested from any Square One Clinics employee at any time.</p>
                        <p>The Chief Administrator is obligated to review any such grievance impartially and without prejudice to the individual(s) filing it, or to the individual for whom it was filed, within 48 hours of receiving it.</p>
                        <p>Upon request, a meeting with the Chief Administrator will be scheduled at no cost to the individual(s) filing the grievance, for the purpose of clarifying and/or resolving the grievance. If a meeting with the Chief Administrator is not requested, the individual(s) who filed the grievance will be notified via telephone of the Chief Administrator's decision/action related to the filed grievance within fve (5) business days of receiving it. If attempts to contact the individual(s) who filed the grievance fail for a period of five (5) additional business days, a certified letter will be mailed to the individuals in lieu of the above mentioned phone call.</p>
                        <p>If the grievance cannot be resolved to the satisfaction of the individual(s) who filed the grievance after every effort has been made to do so by the agency, the individual(s) may file a grievance with the Florida Department of Children &amp; Families, SunCoast Region - Alcohol, Drug Abuse &amp; Mental Health Program Office, 9393 N. Florida Avenue, Suite 1000, Tampa Florida 33612.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>5. INFECTION CONTROL</h2>
                        <p>To help assist in the efforts to prevent the spread of infections or infectious diseases Square One Clinics has set forth policies and procedures relating to Infection Control. Both clients and staff have an obligation to uphold basic infection control standards in an effort to ensure and maintain a safe/healthy environment.</p>
                        <p>Routine hand-washing remains the most effective means of controlling the transmission of pathogens and is the single most important principle of infection control.</p>
                    </div>
                    <div class="avoid-break">
                        <p>Hands should be washed (at minimum):</p>
                        <ul>
                            <li>When hands become soiled</li>
                            <li>Before preparation of food and medication</li>
                            <li>Before and after personal care</li>
                            <li>After using the restroom</li>
                            <li>After touching one's mouth or nose</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <p>Techniques to effective hand washing</p>
                        <ul>
                            <li>Stand away from the sink to avoid clothing contact with it</li>
                            <li>Wet and soap hands thoroughly using tap water</li>
                            <li>Routine hand washing technique should include vigorous friction with soap and running water for at least ten seconds</li>
                            <li>Attention should be given to the area around and under fingernails, between fingers, and the back of the hand</li>
                            <li>The type of soap is secondary to the mechanics and rinsing action</li>
                            <li>Rinse well. Allow water to run from the fingertips to the forearms</li>
                            <li>Grasp paper towel from dispenser. Turn off tap with paper towel placed between hand and faucet shut off; hands will not get re-soiled</li>
                            <li>Discard the paper towel in the receptacle</li>
                        </ul>
                    </div>
                    <div class="avoid-break">
                        <p>In the event the water supply is interrupted, hands are washed using a waterless disinfectant solution or wipe.</p>
                        <p><i>Please Note:</i> It is required that anyone receiving services at our facilities who reports or is known to be infected with, or a carrier of a communicable disease, be reported as such to the County Health Department. Be assured that all such reporting, when required, is done in compliance with guidelines established by the County Health Department for the reporting of communicable diseases.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>CONSENT TO TREATMENT</h2>
                        <p>I am voluntarily seeking counseling services with Square One Clinics. I understand that I have rights and responsibilities regarding my participation in treatment, including the right to discontinue therapy. I am strongly encouraged to discuss my treatment plan and status in treatment with my counselor. Counselors will also discuss alternatives, procedures, qualifications, and drawbacks to therapy.</p>
                        <p>With my signature below, I acknowledge that I have read, understand, and agree to all of the above. I also acknowledge that I have been given a copy of Square One Clinics Privacy Policies.</p>
                    </div>
                    <hr>
                    <div class="avoid-break">
                        <h2 class="align-center">NOTICE OF PRIVACY PRACTICES</h2>
                        <p class="align-center">THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY. ANY REFERENCES IN THIS DOCUMENT TO MEDICAL PRACTICE, MEDICAL RECORDS, MEDICAL SERVICES, ETC. APPLY ALSO TO PSYCHOTHERAPY</p>
                        <p>Square One Clinics understands the importance of privacy and is committed to maintaining the confidentiality of your medical information. Square One Clinics makes a record of the medical care we provide and may receive such records from others. Square One Clinics uses these records to provide or enable other health care providers to provide quality medical care, to obtain payment for services provided to you as allowed by your health plan, and to enable Square One Clinics to meet professional and legal obligations to properly operate this medical practice. Square One Clinics is required by law to maintain the privacy of protected health information, to provide individuals with notice of our legal duties and privacy practices with respect to protected health information, and to notify affected individuals following a breach of unsecured protected health information. This notice describes how Square One Clinics may use and disclose your medical information. It also describes your rights and our legal obligations  with respect to your medical information. If you have any questions about this Notice, please contact the Privacy Officer at (727) 295-1000.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>TABLE OF CONTENTS</h2>
                        <ol class="alpha">
                            <li>How This Medical Practice May Use or Disclose Your Health Information</li>
                            <li>When This Medical Practice May Not Use or Disclose Your Health Information</li>
                            <li>Your Health Information Rights</li>
                            <ol class="number">
                                <li>Right to Request Special Privacy Protections</li>
                                <li>Right to Request Confidential Communications</li>
                                <li>Right to Inspect and Copy</li>
                                <li>Right to Amend or Supplement</li>
                                <li>Right to an Accounting of Disclosures</li>
                                <li>Right to a Paper or Electronic Copy of this Notice</li>
                            </ol>
                            <li>Changes to this Notice of Privacy Practices</li>
                            <li>Complaints</li>
                        </ol>
                    </div>
                    <div class="avoid-break">
                        <h2>A. How This Medical Practice May Use or Disclose Your Health Information</h2>
                        <p>This medical practice collects health information about you and stores it in a chart [and on a computer] [and in an electronic health record/personal health record]. This is your medical record. The medical record is the property of this medical practice, but the information in the medical record belongs to you. The law permits us to use or disclose your health information for the following purposes:</p>
                    </div>
                    <ol>
                        <li class="avoid-break">
                            <b>Treatment.</b> We use medical information about you to provide your medical care. We disclose medical information to our employees and others who are involved in providing the care you need. For example, we may share your medical information with other physicians or other health care providers who will provide services that we do not provide. Or we may share this information with a pharmacist who needs it to dispense a prescription to you, or a laboratory that performs a test. We may also disclose medical information to members of your family or others who can help you when you are sick or injured, or after you die.
                        </li>
                        <li class="avoid-break">
                            <b>Payment.</b> We use and disclose medical information about you to obtain payment for the services we provide. For example, we give your health plan the information it requires before it will pay us. We may also disclose information to other health care providers to assist them in obtaining payment for services they have provided to you.
                        </li>
                        <li class="avoid-break">
                            <b>Care Operations.</b> We may use and disclose medical information about you to operate this medical practice. For example, we may use and disclose this information to review and improve the quality of care we provide, or the competence and qualifications of our professional staff. Or we may use and disclose this information to get your health plan to authorize services or referrals. We may also use and disclose this information as necessary for medical reviews, legal services and audits, including fraud and abuse detection and compliance programs and business planning and management. We may also share your medical information with our "business associates," such as our billing service, that perform administrative services for us. We have a written contract with each of these business associates that contains terms requiring them and their subcontractors to protect the confidentiality and security of your protected health information. We may also share your information with other health care providers, health care clearinghouses or health plans that have a relationship with you, when they request this information to help them with their quality assessment and improvement activities, their patient-safety activities, their population-based efforts to improve health or reduce health care costs, their protocol development, case management or care-coordination activities, their review of competence, qualifications and performance of health care professionals, their training programs, their accreditation, certification or licensing activities, or their health care fraud and abuse detection and compliance efforts.
                        </li>
                        <li class="avoid-break">
                            <b>Appointment Reminders.</b> We may use and disclose medical information to contact and remind you about appointments. If you are not home, we may leave this information on your answering machine or in a message left with the person answering the phone.
                        </li>
                        <li class="avoid-break">
                            <b>Sign In Sheet.</b> We may use and disclose medical information about you by having you sign in when you arrive at our office. We may also call out your name when we are ready to see you.
                        </li>
                        <li class="avoid-break">
                            <b>Notification and Communication With Family.</b> We may disclose your health information to notify or assist in notifying a family member, your personal representative or another person responsible for your care about your location, your general condition or, unless you had instructed us otherwise, in the event of your death. In the event of a disaster, we may disclose information to a relief organization so that they may coordinate these notification efforts. We may also disclose information to someone who is involved with your care or helps pay for your care. If you are able and available to agree or object, we will give you the opportunity to object prior to making these disclosures, although we may disclose this information in a disaster even over your objection if we believe it is necessary to respond to the emergency circumstances. If you are unable or unavailable to agree or object, our health professionals will use their best judgment in communication with your family and others.
                        </li>
                        <li class="avoid-break">
                            <b>Marketing.</b> Provided we do not receive any payment for making these communications, we may contact you to give you information about products or services related to your treatment, case management or care coordination, or to direct or recommend other treatments, therapies, health care providers or settings of care that may be of interest to you. We may similarly describe products or services provided by this practice and tell you which health plans this practice participates in. We may also encourage you to maintain a healthy lifestyle and get recommended tests, participate in a disease management program, provide you with small gifts, tell you about government sponsored health programs or encourage you to purchase a product or service when we see you, for which we may be paid. Finally, we may receive compensation which covers our cost of reminding you to take and refill your medication, or otherwise communicating about a drug or biologic that is currently prescribed for you. We will not otherwise use or disclose your medical information for marketing purposes or accept any payment for other marketing communications without your prior written authorization. The authorization will disclose whether we receive any compensation for any marketing activity you authorize, and we will stop any future marketing activity to the extent you revoke that authorization.
                        </li>
                        <li class="avoid-break">
                            <b>Sale of Health Information.</b> We will not sell your health information without your prior written
                            authorization. The authorization will disclose that we will receive compensation for your health
                            information if you authorize us to sell it, and we will stop any future sales of your information to
                            the extent that you revoke that authorization.
                        </li>
                        <li class="avoid-break">
                            <b>Required by Law.</b> required by law, we will use and disclose your health information, but we
                            will limit our use or disclosure to the relevant requirements of the law. When the law requires us
                            to report abuse, neglect or domestic violence, or respond to judicial or administrative
                            proceedings, or to law enforcement officials, we will further comply with the requirement set forth
                            below concerning those activities.
                        </li>
                        <li class="avoid-break">
                            <b>Public Health.</b> We may, and are sometimes required by law, to disclose your health
                            information to public health authorities for purposes related to: preventing or controlling disease,
                            injury or disability; reporting child, elder or dependent adult abuse or neglect; reporting domestic
                            violence; reporting to the Food and Drug Administration problems with products and reactions to
                            medications; and reporting disease or infection exposure. When we report suspected elder or
                            dependent adult abuse or domestic violence, we will inform you or your personal representative
                            promptly unless in our best professional judgment, we believe the notification would place you
                            at risk of serious harm or would require informing a personal representative we believe is
                            responsible for the abuse or harm.
                        </li>
                        <li class="avoid-break">
                            <b>Health Oversight Activities.</b> We may, and are sometimes required by law, to disclose your health
                            information to health oversight agencies during audits, investigations, inspections, licensure and
                            other proceedings, subject to the limitations imposed by law.
                        </li>
                        <li class="avoid-break">
                            <b>Judicial and Administrative Proceedings.</b> We may, and are sometimes required by law, to
                            disclose your health information in the course of any administrative or judicial proceeding to the
                            extent expressly authorized by a court or administrative order. We may also disclose information
                            about you in response to a subpoena, discovery request or other lawful process if reasonable
                            efforts have been made to notify you of the request and you have not objected, or if your
                            objections have been resolved by a court or administrative order.
                        </li>
                        <li class="avoid-break">
                            <b>Law Enforcement.</b> We may, and are sometimes required by law, to disclose your health
                            information to a law enforcement official for purposes such as identifying or locating a suspect,
                            fugitive, material witness or missing person, complying with a court order, warrant, grand jury
                            subpoena and other law enforcement purposes.
                        </li>
                        <li class="avoid-break">
                            <b>Coroners.</b> We may, and are often required by law, to disclose your health information to
                            coroners in connection with their investigations of deaths.
                        </li>
                        <li class="avoid-break">
                            <b>Public Safety.</b> We may, and are sometimes required by law, to disclose your health
                            information to appropriate persons in order to prevent or lessen a serious and imminent threat to
                            the health or safety of a person or the general public.
                        </li>
                        <li class="avoid-break">
                            <b>Specialized Government Functions.</b> We may disclose your health information for military or
                            national security purposes or to correctional institutions or law enforcement officers that have
                            you in their lawful custody.
                        </li>
                        <li class="avoid-break">
                            <b>Workers’ Compensation.</b> We may disclose your health information as necessary to comply
                            with workers’ compensation laws. For example, to the extent your care is covered by workers'
                            compensation, we will make periodic reports to your employer about your condition. We are also
                            required by law to report cases of occupational injury or occupational illness to the employer or
                            workers' compensation insurer.
                        </li>
                        <li class="avoid-break">
                            <b>Change of Ownership.</b> If this medical practice is sold or merged with another organization,
                            your health information/record will become the property of the new owner, although you will
                            maintain the right to request that copies of your health information be transferred to another
                            physician or medical group.
                        </li>
                        <li class="avoid-break">
                            <b>Breach Notification.</b> In the case of a breach of unsecured protected health information, we
                            will notify you as required by law. If you have provided us with a current email address, we may
                            use email to communicate information related to the breach. In some circumstances our
                            business associate may provide the notification. We may also provide notification by other
                            methods as appropriate.
                        </li>
                        <li class="avoid-break">
                            <b>Psychotherapy Notes.</b> We will not use or disclose your psychotherapy notes without your
                            prior written authorization except for the following:
                            <ol class="alpha-lower">
                                <li class="avoid-break">Use by the originator of the notes for your treatment,</li>
                                <li class="avoid-break">For training our staff, students and other trainees,</li>
                                <li class="avoid-break">To defend ourselves if you sue us or bring some other legal proceeding,</li>
                                <li class="avoid-break">If the law requires us to disclose the information to you or the Secretary of HHS or forsome other reason,</li>
                                <li class="avoid-break">In response to health oversight activities concerning your psychotherapist,</li>
                                <li class="avoid-break">To avert a serious and imminent threat to health or safety, or</li>
                                <li class="avoid-break">To the coroner or medical examiner after you die.</li>
                            </ol>
                        </li>
                        <p>
                            To the extent you revoke an authorization to use or disclose your psychotherapy notes, we will stop using or disclosing these notes.
                        </p>
                        <li class="avoid-break">
                            <b>Research.</b> We may disclose your health information to researchers conducting research with respect to which your written authorization is not required as approved by an Institutional Review Board or privacy board, in compliance with governing law.
                        </li>
                        <li class="avoid-break">
                            <b>Fundraising.</b> We may use or disclose your demographic information in order to contact you for our fundraising activities. For example, we may use the dates that you received treatment, the department of service, your treating physician, outcome information and health insurance status to identify individuals that may be interested in participating in fundraising activities. If you do not want to receive these materials, notify the Privacy Officer listed at the top of this Notice of Privacy Practices and we will stop any further fundraising communications. Similarly, you should notify the Privacy Officer if you decide you want to start receiving these solicitations again.
                        </li>
                    </ol>
                    <div class="avoid-break">
                        <h2>B. When This Medical Practice May Not Use or Disclose Your Health Information</h2>
                        <p>Except as described in this Notice of Privacy Practices, this medical practice will, consistent with its legal obligations, not use or disclose health information which identifies you without your written authorization. If you do authorize this medical practice to use or disclose your health information for another purpose, you may revoke your authorization in writing at any time.</p>
                    </div>
                    <h2>C. Your Health Information Rights</h2>
                    <ol>
                        <li class="avoid-break">
                            <b>Right to Request Special Privacy Protections.</b> You have the right to request restrictions on certain uses and disclosures of your health information by a written request specifying what information you want to limit, and what limitations on our use or disclosure of that information you wish to have imposed. If you tell us not to disclose information to your commercial health plan concerning health care items or services for which you paid for in full out-of-pocket, we will abide by your request, unless we must disclose the information for treatment or legal reasons. We reserve the right to accept or reject any other request and will notify you of our decision.
                        </li>
                        <li class="avoid-break">
                            <b>Right to Request Confidential Communications.</b> You have the right to request that you receive your health information in a specific way or at a specific location. For example, you may ask that we send information to a particular email account or to your work address. We will comply with all reasonable requests submitted in writing which specify how or where you wish to receive these communications.
                        </li>
                        <li class="avoid-break">
                            <b>Right to Inspect and Copy.</b> You have the right to inspect and copy your health information, with limited exceptions. To access your medical information, you must submit a written request detailing what information you want access to, whether you want to inspect it or get a copy of it, and if you want a copy, your preferred form and format. We will provide copies in your requested form and format if it is readily producible, or we will provide you with an alternative format you find acceptable, or if we can’t agree and we maintain the record in an electronic format, your choice of a readable electronic or hardcopy format. We will also send a copy to any other person you designate in writing. We will charge a reasonable fee which covers our costs for labor, supplies, postage, and if requested and agreed to in advance, the cost of preparing an explanation or summary. We may deny your request under limited circumstances. If we deny your request to access your child's records or the records of an incapacitated adult you are representing because we believe allowing access would be reasonably likely to cause substantial harm to the patient, you will have a right to appeal our decision. If we deny your request to access your psychotherapy notes, you will have the right to have them transferred to another mental health professional.
                        </li> 
                        <li class="avoid-break">
                            <b>Right to Amend or Supplement.</b> You have a right to request that we amend your health information that you believe is incorrect or incomplete. You must make a request to amend in writing and include the reasons you believe the information is inaccurate or incomplete. We are not required to change your health information and will provide you with information about this medical practice's denial and how you can disagree with the denial. We may deny your request if we do not have the information, if we did not create the information (unless the person or entity that created the information is no longer available to make the amendment), if you would not be permitted to inspect or copy the information at issue, or if the information is accurate and complete as is. If we deny your request, you may submit a written statement of your disagreement with that decision, and we may, in turn, prepare a written rebuttal. All information related to any request to amend will be maintained and disclosed in conjunction with any subsequent disclosure of the disputed information. 
                        </li>
                        <li class="avoid-break">
                            <b>Right to an Accounting of Disclosures.</b> You have a right to receive an accounting of disclosures of your health information made by this medical practice, except that this medical practice does not have to account for the disclosures provided to you or pursuant to your written authorization, or as described in paragraphs 1 (treatment), 2 (payment), 3 (health care operations), 6 (notification and communication with family) and 8 (specialized government functions) of Section A of this Notice of Privacy Practices or disclosures for purposes of research or public health which exclude direct patient identifiers, or which are incident to a use or disclosure otherwise permitted or authorized by law, or the disclosures to a health oversight agency or law enforcement official to the extent this medical practice has received notice from that agency or official that providing this accounting would be reasonably likely to impede their activities. 
                        </li>
                        <li class="avoid-break">
                            <b>Right to a Paper or Electronic Copy of this Notice.</b> You have a right to notice of our legal duties and privacy practices with respect to your health information, including a right to a paper copy of this Notice of Privacy Practices, even if you have previously requested its receipt by email. If you would like to have a more detailed explanation of these rights or if you would like to exercise one or more of these rights, contact our Privacy Officer listed at the top of this Notice of Privacy Practices.
                        </li>
                    </ol>
                    <div class="avoid-break">
                        <h2>D. Changes to this Notice of Privacy Practices</h2>
                        <p>We reserve the right to amend this Notice of Privacy Practices at any time in the future. Until such amendment is made, we are required by law to comply with the terms of this Notice currently in effect. After an amendment is made, the revised Notice of Privacy Protections will apply to all protected health information that we maintain, regardless of when it was created or received. We will keep a copy of the current notice posted in our reception area, and a copy will be available at each appointment. We will also post the current notice on our website.</p>
                    </div>
                    <div class="avoid-break">
                        <h2>E. Complaints</h2>
                        <p>Complaints about this Notice of Privacy Practices or how this medical practice handles your health information should be directed to our Privacy Officer listed at the top of this Notice of Privacy Practices. If you are not satisfied with the way this office handles a complaint, you may submit a formal complaint by using the form from the website below: The complaint form may be found at <a href="http://www.hhs.gov/ocr/privacy/" class="a" target="_blank">www.hhs.gov/ocr/privacy/hipaa/complaints/hipcomplaint.pdf</a>. You will not be penalized in any way for filing a complaint.</p>
                    </div>
                </div>
<!--/////////////// END FORM CONTENT ///////////////-->
                <div class="avoid-break">
                    <Input class="block bottom-15" type='text' id='notes' v-model='rec.notes' label='Name' />
                    <Input class="block bottom-30" type='date' id='consent_date' v-model='rec.consent_date' label='Consent Date' />
                    <div class="flex bottom max500 bottom-30" v-if="!signature_ids.length">
                        <div>Client Name:</div>
                        <hr class="flex-1 gray margin-0">
                    </div>
                    <div class="flex bottom bottom-30 max500" v-if="!signature_ids.length">
                        <div>Client Signature:</div>
                        <hr class="flex-1 gray margin-0">
                    </div>
                    <div class="align-right" v-if="!signature_ids.length">
                        <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                            Cancel Edit
                        </button>
                        <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />
                    </div>
                    <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                    <FormSignatureButton
                        :rec="rec"
                        :original_rec="original_rec"
                        :signatures_info="signatures_info"
                        :fileName="pageMeta.component_name"
                        :signature_ids="signature_ids"
                        :record_id="record_id"
                        @resetView="reloadData"
                        @fail="fail2sign"
                        @showModal="showPdfModal"
                        :save="updateRecord"
                    />
                </div>
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal">
                <div>
                    <SignConvertPdfModal />
                </div>
            </VIfWorkableModal>
        </div>
    </div>
</template>

<script>
    // const api_root = 'form_content/page_contents/' + this.$store.pageMeta().id
/*
<div v-html="record_data.page_contents.consent_policy"></div>
 */
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'ConsentForm',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        },
    }
</script>